import { Container, Row, Col } from "react-bootstrap";
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
      <div className="overlay"></div>

        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}> 
            {/* <img src={logo} alt="Logo" /> */}
            Codyinger
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div  className="social mt-5">
              <h5>For Queries</h5>
              {/* <div style={{border:"1px solid red"}}><EmailIcon />codyinger2023@gmail.com</div><br /> */}
              <div><WhatsAppIcon /> +91 8281909477</div>
              <div><EmailIcon /> codyinger2023@gmail.com</div>
            </div>
            <div  className="social-icon mt-3" >
              <a href="#"><FacebookIcon style={{color:"white"}} /></a>
              <a href="#" ><LinkedInIcon style={{color:'white'}} /></a>
              <a href="#"> <WhatsAppIcon style={{color:'white'}} /></a>
              <a href="#"> <TelegramIcon style={{color:'white'}} /></a>
              <a href="#"> <InstagramIcon style={{color:'white'}}/></a>
             
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
