import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contactus.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formEle = document.querySelector('form');
    setButtonText("Sending...");
    const formData = new FormData(formEle);
    // formData.time= new Date()

    fetch('https://script.google.com/macros/s/AKfycbxswkpK3MmmKoomZKYLJXgpuqXg7O21gCOxd3ZLIB83k-xkFt8PRXFd0DeM2UcdgSg/exec', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to send the message.");
        }
      })
      .then((data) => {
        console.log(data);
        formEle.reset();
        setButtonText("Send");
        setStatus({ success: true, message: data.message });
      })
      .catch((error) => {
        console.error(error);
        setButtonText("Send");
        setStatus({ success: false, message: 'Something went wrong, please try again later.' });
      });
  };

  function formatTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.toLocaleString('en-US', { month: 'short' });
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
  
    return `${month} ${day} ${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated" : ""}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit} className="form">
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                      <input hidden type="text" name='timestamp' value={formatTimestamp()} />



                        <input type="text" name='firstName' placeholder="First Name" required />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" name='lastName' placeholder="Last Name" required />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="email" name='email' placeholder="Email Address" required />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="tel" name='phone' placeholder="Phone No." maxLength={10} required />
                      </Col>
                      <Col size={12} className="px-1" sm={12}> 
                        <textarea rows="6" placeholder="Message" name='message' required></textarea>
                        <button type="submit"><span>{buttonText}</span></button>
                      </Col>
                      {status.message &&
                        <Col>
                          <p style={{marginTop:"12px"}} className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
