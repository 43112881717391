import React from "react";
import meter1 from "../assets/img/javas.png";
import meter2 from "../assets/img/python.png";
import meter3 from "../assets/img/react.png";
import meter4 from "../assets/img/go.png";
import meter5 from "../assets/img/Typescript.svg.png";
import meter6 from "../assets/img/docer.png";    
import meter7 from "../assets/img/momgo.png";    
import meter8 from "../assets/img/postgress.jpg";    
import meter9 from "../assets/img/git.png";    
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import arrow1 from "../assets/img/arrow1.svg";
// import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/wallpaperflare.com_wallpaper (3).jpg";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="courses">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Courses</h2>
              <p>
              Elevate your knowledge and stay ahead in the ever-evolving world of <br />technology with our expertly crafted courses.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={1000} // Set the auto-play interval in milliseconds (e.g., 3000ms for 3 seconds)
                stopOnHover={true} // Stops auto-play on hover
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="Image" />
                  <h5>JavaScript</h5>
                </div>
                <div className="item">
                  <img src={meter2} alt="Image" />
                  <h5>Python</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="Image" />
                  <h5>React JS</h5>
                </div>
                <div className="item">
                  <img src={meter4} alt="Image" />
                  <h5>Golang</h5>
                </div>
                <div className="item">
                  <img src={meter5} alt="Image" />
                  <h5>TypeScript</h5>
                </div>
                <div className="item">
                  <img src={meter6} alt="Image" />
                  <h5>Docker</h5>
                </div>
                <div className="item">
                  <img src={meter7} alt="Image" />
                  <h5>MongoDB</h5>
                </div>
                <div className="item">
                  <img src={meter8} alt="Image" />
                  <h5>PostgreSQL</h5>
                </div>
                <div className="item">
                  <img src={meter9} alt="Image" />
                  <h5>Git</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
